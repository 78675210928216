import React from 'react'
import './Roadmap.css'
const Roadmap = () => {
    return (
        <section className="roadmap" id='roadmapSrl'>
            <div className="container">
                <div className='titlebox'>
                    <h6>Oxchange Roadmap</h6>
                    <h2>Futuristic Roadmap</h2>
                    <p>Our Roadmap</p>
                </div>
                <div className="row roadMapBox">
                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="innerInfoBox disabled">
                            <span>2023</span>
                            <p>1. Launch Of Oxchange <b>✓</b></p>
                            <p>2. V3 Dex <b>✓</b></p>
                            <p>3. Marketing <b>✓</b></p>
                            <p>4. Airdrop Campaign <b>✓</b></p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="innerInfoBox disabled">
                            <span>2024</span>
                            <p>1. Marketing Campaign <b>✓</b></p>
                            <p>2. Oxchange Games <b>✓</b></p>
                            <p>3. Decentralized Wallet <b>✓</b></p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="innerInfoBox">
                            <span>Q1 2025</span>
                            <p>1. Centralized Exchange</p>
                            <p>2. Decentralized Loans</p>
                            <p>3. OC Contests (Meme, etc)</p>
                            <p>4. Lottery</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="innerInfoBox">
                            <span>Q4 2025</span>
                            <p>1. Futures/ Options Trading (Decentralized).</p>
                            <p>2. ZK EVM Blockchain</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Roadmap